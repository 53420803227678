import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkmark"];

  static values = {
    participantId: Number,
    participantName: String,
  };

  toggle() {
    this.sendParticipant();
    this.checkmarkTarget.classList.toggle("hidden");
  }

  sendParticipant() {
    this.element.dispatchEvent(
      new CustomEvent("participantToggled", {
        detail: {
          id: this.participantIdValue,
          name: this.participantNameValue,
        },
        bubbles: true,
      })
    );
  }
}
