import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["startDate", "endDate"];

  connect() {

  }

  selectDateRange(event) {
    const {
      detail: { selectedDates },
    } = event;

    if (selectedDates.length === 2) {
      [this.startDateTarget.value, this.endDateTarget.value] = [
        selectedDates[0].toLocaleDateString("se"),
        selectedDates[1].toLocaleDateString("se"),
      ];
    }
  }
}
