import { Controller } from "@hotwired/stimulus";

export default class FinalSubmitController extends Controller {
  static targets = [
    "submitButton",
    "errorMessage",
    "errorTosPp",
    "termsOfService",
    "privacyPolicy",
    "modal",
    "spinner",
    "paymentOptions",
    "partialPayment",
    "singlePayment",
    "partialPaymentField",
    "paymentButton",
  ];

  static values = {
    bookingResourceSkus: Array,
    bookingResourceSkuParticipantIds: Object,
    bookingResourceSkuQuantities: Object,
    errorMessage: String,
    tosAndPpMessage: String,
  };

  connect() {
    this.handleSubmit();
  }

  handleChange() {
    if (this.partialPaymentTarget.checked) {
      this.partialPaymentFieldTarget.value = true;
    } else if (this.singlePaymentTarget.checked) {
      this.partialPaymentFieldTarget.value = false;
    }

    this.paymentButtonTarget.disabled = false;
  }

  showSpinner() {
    this.paymentOptionsTarget.classList.add("hidden");
    this.spinnerTarget.classList.remove("hidden");
  }

  checkTermsOfServiceAndPrivacyPolicy() {
    if (this.termsOfServiceTarget.checked && this.privacyPolicyTarget.checked) {
      this.tosAndPpAccepted = true;
      this.errorTosPpTarget.innerHTML = "";
    } else {
      this.tosAndPpAccepted = false;
      this.errorTosPpTarget.innerHTML = this.tosAndPpMessageValue;
    }
  }

  handleSubmit() {
    this.allParticipantsAssigned = true;
    this.bookingResourceSkusValue.forEach((el) => {
      const { id } = el;

      if (
        this.bookingResourceSkuParticipantIdsValue[id].length !==
        this.bookingResourceSkuQuantitiesValue[id]
      ) {
        this.allParticipantsAssigned = false;
      }
    });

    this.checkTermsOfServiceAndPrivacyPolicy();

    if (this.allParticipantsAssigned) {
      this.errorMessageTarget.innerHTML = "";
    } else {
      this.errorMessageTarget.innerHTML = this.errorMessageValue;
    }

    if (this.allParticipantsAssigned && this.tosAndPpAccepted) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }

  handleParticipantAssignment(event) {
    const { bookingResourceSkuId, selectedParticipantsIds } = event.detail;

    this.bookingResourceSkuParticipantIdsValue =
      FinalSubmitController.updateObject(
        this.bookingResourceSkuParticipantIdsValue,
        bookingResourceSkuId,
        selectedParticipantsIds
      );

    this.handleSubmit();
  }

  showCheckoutModal() {
    this.modalTarget.classList.remove("hidden");
  }

  closeCheckoutModal() {
    this.modalTarget.classList.add("hidden");
    this.partialPaymentFieldTarget.value = false;
    this.partialPaymentTarget.checked = false;
    this.singlePaymentTarget.checked = false;
    this.paymentButtonTarget.disabled = true;
  }

  static updateObject(obj, key, value) {
    const updatedObj = obj;
    updatedObj[key] = value;
    return updatedObj;
  }
}
